"use client";
import "swiper/css";
import "swiper/css/navigation";
import { ReactNode, useRef, useState, type JSX } from "react";
import { Modal } from "../../modals";

import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination } from "swiper";
import { GalleryPreviewSlide } from "./GalleryPreviewSlide";
import { Preview } from "..";

type Props = {
  children: ReactNode | ReactNode[] | JSX.Element | JSX.Element[] | any;
  handleDownload?: boolean;
  fetcher: any;
};

export function GalleryPreview({ children, handleDownload = false, fetcher }: Props) {
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [pictureIndex, setPictureIndex] = useState<number>(0);

  const previews = (Array.isArray(children) ? children : [children]).flat();

  function onClick(e: any, index: number) {
    e.stopPropagation();
    setIsModalOpen(true);
    setPictureIndex(index);
  }
  const modalRef = useRef(null);

  return (
    <>
      {previews?.map((preview, index) => preview && <Preview {...preview?.props} onClick={(e) => onClick(e, index)} key={"preview-" + index} />)}

      <Modal showModal={isModalOpen} setShowModal={setIsModalOpen} id="galleryModal">
        <Swiper
          ref={modalRef}
          initialSlide={pictureIndex}
          spaceBetween={15}
          navigation={true}
          pagination={{
            clickable: true,
          }}
          modules={[Navigation, Pagination]}
          className="gallerySwiper"
        >
          {previews?.map((preview, index) => (
            <SwiperSlide key={`slide_${index}`}>
              <GalleryPreviewSlide {...preview?.props} handleDownload={handleDownload} fetcher={fetcher} />
            </SwiperSlide>
          ))}
        </Swiper>
      </Modal>
    </>
  );
}
