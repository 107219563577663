"use client";
import { useRef } from "react";
import { apiURL } from "../../../../utilities/config/server";
import { QuestionAttachmentType, QuestionExpertAttachmentType } from "../../../../utilities/redux/types";
import { IconButton } from "../../buttons";
import { BsFiletypeDocx } from "../../../react-icons/bs";
import { CsvPreview } from "../../files";
import { AxiosInstance } from "axios";
import { XlsxPreview } from "../../files";
import { Shape } from "../../shapes";
import { IoIosAttach } from "../../../react-icons/io";

type Props = {
  attachment: QuestionAttachmentType | QuestionExpertAttachmentType;
  app: "company" | "expert" | "admin";
  senderType: "" | "_from_company" | "_from_expert";
  handleDownload?: boolean;
  fetcher: AxiosInstance;
};

export function GalleryPreviewSlide({ attachment, app, handleDownload, senderType = "", fetcher }: Props) {
  const inputRef: any = useRef(null);
  const extAccepted = ["csv", "pdf", "xls", "xlsx", "docx", "jpeg", "jpg", "bmp", "png"];

  function download(e: any) {
    e.stopPropagation();
    handleDownload && inputRef?.current?.click();
  }

  return (
    <div className="galleryPreview">
      <a ref={inputRef} href={`${apiURL}${app}/questionAttachment/download${senderType}/${attachment?.id}`} download />

      <div className={"img-container"} >
        {(attachment?.originalExtension?.toLowerCase() === "jpeg" ||
          attachment?.originalExtension?.toLowerCase() === "jpg" ||
          attachment?.originalExtension?.toLowerCase() === "bmp" ||
          attachment?.originalExtension?.toLowerCase() === "png") ?
          <img
            src={
              attachment?.preview
                ? attachment?.preview
                : `${apiURL}${app}/questionAttachment/questionAttachment${senderType}/${attachment?.id}&time=${new Date()}`
            }
            alt="attachment"
          />
          : <>
            {(attachment?.originalExtension?.toLowerCase() === "pdf")
              && <iframe
                src={
                  attachment?.preview
                    ? attachment.preview
                    : `${apiURL}${app}/questionAttachment/questionAttachment${senderType}/${attachment?.id}`
                }
                height="100%"
                width="100%"
                frameBorder="0"
              ></iframe>}

            {(attachment?.originalExtension?.toLowerCase() === "xlsx" || attachment?.originalExtension?.toLowerCase() === "xls") &&
              <XlsxPreview file={attachment} app={app} senderType={senderType} fetcher={fetcher} />
            }

            {attachment?.originalExtension?.toLowerCase() === "docx" &&
              <div className="without-image clickable">
                <BsFiletypeDocx />
                <p className="name">{attachment?.originalBaseName}</p>
              </div>}

            {attachment?.originalExtension?.toLowerCase() === "csv" && (
              <CsvPreview file={attachment} app={app} senderType={senderType} fetcher={fetcher} />
            )}


            {!extAccepted.includes(attachment?.originalExtension?.toLowerCase()) && <div className="without-image">
              <Shape form="square" color="infoBackground">
                <IoIosAttach />
              </Shape>
              <p className="name">{attachment?.originalBaseName}</p>
            </div>}
          </>}
      </div>

      {handleDownload && (
        <IconButton
          type="gray"
          icon="download"
          cb={(e) => download(e)}
          size={18}
          form="circle"
          tooltip="Télécharger la pièce jointe"
          tooltipPosition="left"
        />
      )}
    </div>
  );
}
