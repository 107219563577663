export function AccountingFirmPlaceholder() {
  return (
    <>
      <svg
        id="Calque_1"
        data-name="Calque 1"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 200 200"
        className="placeholder"
      >
        <rect className="cls-3" x="30.91" y="155.71" width="138.17" height="8.28" />
        <path
          className="cls-3"
          d="m166.79,76.7H33.21c-1.27,0-1.76-1.83-.68-2.58L99.32,27.39c.42-.29.95-.29,1.36,0l66.79,46.73c1.07.75.59,2.58-.68,2.58Z"
        />
        <text className="cls-1" transform="translate(89.47 65.82)">
          <tspan x="0" y="0">
            $
          </tspan>
        </text>
        <rect className="cls-3" x="23" y="166.9" width="154" height="8.28" />
        <g>
          <rect className="cls-3" x="44.76" y="79.61" width="14.94" height="73.19" />
          <rect className="cls-3" x="92.53" y="79.61" width="14.94" height="73.19" />
          <rect className="cls-3" x="140.31" y="79.61" width="14.94" height="73.19" />
        </g>
      </svg>
    </>
  );
}
