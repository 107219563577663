"use client";

import { HTMLAttributes, useEffect, useRef, useState } from "react";
import classNames from "classnames";

import { serverURL } from "../../../../utilities/config/server";
import { MdModeEditOutline } from "../../../react-icons/md";
import { CompanyPermissionType, UserType } from "../../../../utilities/redux/types";
import { UserCompanyPermissionIcon } from "../../icons";
import { UserPlaceholder } from "./UserPlaceholder";

interface Props extends HTMLAttributes<HTMLDivElement> {
  email?: string;
  companyPermission?: CompanyPermissionType;
  alt?: string;
  size?: number;
  className?: string;
  handleModify?: (e: any) => void;
  preview?: string;
  type?: "thumbnail" | "profil";
  isPictureDefined?: boolean;
  updatedAt?: string;
  user?: UserType;
}

export function UserPicture({
  email,
  companyPermission,
  size = 30,
  className = "",
  type = "thumbnail",
  preview,
  handleModify,
  isPictureDefined = false,
  updatedAt,
  children,
  user,
  ...props
}: Props) {
  const [notFound, setNotFound] = useState(false);
  const inputRef: any = useRef(null);

  useEffect(() => {
    preview && setNotFound(false);
  }, [isPictureDefined, email, preview]);

  return (
    <div
      className={classNames("profil-picture picture ", className, { clickable: handleModify })}
      style={{ width: size + "px", height: size + "px" }}
      {...props}
      onClick={handleModify ? () => inputRef?.current?.click() : () => { }}
    >
      {/*/////////////// Clickable ///////////////////////////////////*/}

      {handleModify && <MdModeEditOutline className="modify icon" size={40} />}

      <input ref={inputRef} hidden type="file" accept="image/*" onChange={handleModify ? (e: any) => handleModify(e?.target?.files) : () => { }} />

      <div className="img-container">
        {handleModify && <div className="cache" />}

        {(isPictureDefined == true || user?.isPictureDefined) && notFound == false ? (
          <img
            // fill
            src={preview ? preview : `${serverURL}/api/user/${type}/${btoa(email)}?time=${(updatedAt || user?.updatedAt) ? Date.parse(new Date(updatedAt ?? user?.updatedAt)?.toISOString()) : null}`}
            aria-hidden
            onError={({ currentTarget }) => {
              setNotFound(true);
              return;
            }}
          />
        )
          : <UserPlaceholder user={user} size={size} />}
      </div>

      {companyPermission && <UserCompanyPermissionIcon companyPermission={companyPermission} size={Math.floor(size * 0.4)} />}

      {children}
    </div>
  );
}
