export function CompanyPlaceholder() {
  return (
    <>
      <svg id="Calque_1" data-name="Calque 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 200 200" className="placeholder">
        <rect className="cls-2" x="36.83" y="34.66" width="81.45" height="134.3" />
        <path className="cls-2" d="m172.4,168.96h-46.72v-94c0-1.22,1.29-2,2.37-1.44l43.48,22.35c.54.28.88.84.88,1.44v71.65Z" />
        <rect style={{ fill: "var(--color-blue-light)" }} className="cls-2" x="63.58" y="138.32" width="27.95" height="34.21" />
        <rect style={{ fill: "var(--color-blue-light)" }} className="cls-2" x="51.51" y="50.49" width="52.09" height="13.53" />
        <rect style={{ fill: "var(--color-blue-light)" }} className="cls-2" x="51.51" y="72.45" width="52.09" height="13.53" />
        <rect style={{ fill: "var(--color-blue-light)" }} className="cls-2" x="51.51" y="94.4" width="52.09" height="13.53" />
      </svg>
    </>
  );
}
